"use strict";
/**
 * @decorators decoratedComponents
 */

var App =  require('src/core/AppIgnition');
var ClientComponentDecorator = require('src/coreDecorators/ClientComponentDecorator');
var SlidingPanelComponentDecorator = require('src/coreDecorators/SlidingPanelComponentDecorator');


var decorateTypes = function() {

	App.decoratedTypes = {};
	
	// DOC : 
	//
	// Method A :
	//
	// -*- The FileSelector : 
	// 		- MUST inherit from the TypeListComponent (which itself inherits form the LazySlottedCompoundComponent)
	//			=> it then hosts a typedSlot (the typedSlots property is an array of ReactiveDatasets)
	// 		- SHALL implement the ClientComponentInterface because of the decoration made by the ClientComponentDecorator
	//		- MAY define a "slotsAssociation" object, indicating the typedSlot which shall handle the response
	//			(by default, the first one will be used. => see ClientComponentInterface.prototype.subscribeToProvider)
	// -*- The ClientComponentInterface is an intermediate interface which inherits by default from the APIConsumerInterface, and extends it.
	//		(It may inherit from another interface : see the comment on the example of code below)
	// -*- The APIConsumerInterface subscribes through reactOnSelf to the "serviceChannel" stream on the FileSelector component,
	//		and is responsible for handling the received data from the API response
	// -*- When the FileSelector subscribes to an APIEndpoint,
	//		the APIEndpointManager checks for the presence of a stream named "serviceChannel" (provided by the ClientComponentInterface)
	// -*- When the FileSelector component calls "acquireAsync()"
	//		(method provided by the APIConsumerInterface, which is called by the ClientComponentInterface through its "dataLink" property )
	// 		the "serviceChannel" stream is populated with the response to the request,
	//		after having eventually been "transformed" by the dataPresenterFunc,
	//		and so, the FlieSelector's "serviceChannel" stream, bound in the APIEndpointManager, is populated
	// -*- The "reactOnSelf" subscription on the FileSelector's "serviceChannel" (added by the APIConsumerInterface) being purely abstract,
	//		it doesn't handle lists : it assumes it will at least receive A list of ONE list,
	// 		and the corresponding typedSlot is populated with each member of the list of lists
	// -*- This typedSlot is meant to instantiate a type of complex sub-component,
	//		(which may subscribe to the "updateChannel" stream of the FileSelector), and handle the content of the list it's been passed,
	//		potentially at a way lower level in the hierarchy of components
	// -*- As the FileSelector is only meant to handle a children-list made of simple components,
	//		it MUST at least host ONE child-component, eg. a FileSelectorFileListHost,
	//		(which may be responsible for subscribing to the updateChannel), and for handling the content of the list received from the API
	// -*- This child-component :
	//		- MUST define a schema for the typedSlot is hosts (or it will be defaulted to an arbitrary and "dumb" string):
	//			=> eg. this.typedSlots[0].setSchema(['fileName']);)
	// -*- The slotDef defined for the typedSlot hosted by the child-component :
	//		- IS SUPPOSED TO have a "prop" declared with the same name as the name of the schema set by the child-component
	//			=> each time the child-component will handle an entry from the array received from the database,
	//				the instanciated component (defined in the slotDef) will be passed the value of that entry
	//				via the stream named from the "schema name" given by the child-component
	
//	App.decoratedTypes.FileSelectorWithDataProvider = SlidingPanelComponentDecorator(
//		ClientComponentDecorator('FileSelector')	// We may here pass an alternative API interface as 2nd argument : eg. 'SingleEndPointScaleTypeConsumerInterface'
//	);
	
	//
	// Method B :
	//
	// -*- Is the same as Method A for the description of the decorators and the interfaces.
	// -*- The SlidingFileSelector is the one which is decorated with the APIConcumerInterface :
	//		- it SHALL implement the ClientComponentInterface because of the decoration made by the ClientComponentDecorator
	// -*- The FileSelector :
	//		- INHERITS from the TypedListComponent 
	//			=> it then hosts a typedSlot, and is responsible for instanciating the members of the list (the slotted components) see next point...
	// -*- The FileSelector subscribes via reactOnParent to the serviceChannel on the APIConsuming-SlidingFileSelctor
	// -*- The FileSelector :
	//		- MUST define a schema for the typedSlot is hosts (or it will be defaulted to an arbitrary and "dumb" string):
	//			=> eg. this.typedSlots[0].setSchema(['fileName']);)
	// -*- The slotDef defined for the typedSlot hosted by the FileSelector :
	//		- IS SUPPOSED TO have a "prop" declared with the same name as the name of the schema set by the FileSelector
	//			=> each time the FileSelector will handle an entry from the array received from the database (via its subscription to the "serviceChannel" stream),
	//				the instanciated component (from the slotDef) will be passed the value of that entry
	//				on the stream named from the "schema name" of the fileSelector
	//
	App.componentTypes.SlidingFileSelector = SlidingPanelComponentDecorator(App.componentTypes.FileSelector);
	App.decoratedTypes.FileSelectorWithDataProvider = ClientComponentDecorator('SlidingFileSelector');
	


	
}

 module.exports = decorateTypes;