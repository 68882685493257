"use strict";
/**
 * @extends passswordForm
 */


 
 var extendsPasswordForm = function(passwordForm, prefix) {
	 
	 // The request is sent by the Form Component itself, via the value of its "action" named stream
	 // The password hashing is also handled in the getFormData method of the Commponent, via a "hard coded"
	 // regex matching on "password" in the name of the field
	 
//	 passwordForm.sendRequest = function() {
//		 this.getFormData();
//		 // content of password field shall be in this.data.entries (a function that returns an iterator)
//		 
//		 for (var entryPair of this.data.entries()) {
//			 if (entryPair[0] === 'apipassword') {
//				 this.data.set(entryPair[0], md5(entryPair[1]));
//			 }
//		 }
		 
		 
//		 var options = {
//			method : 'POST',
//			body : this.data
//		}
//		var url = prefix + 'verify_password/';
//		fetch(url, options).then(function(response) {
//			response.text().then(function(responseObj) {
//				if (responseObj.success === 'file_write_success') {
//					passwordModalBoxComponent.streams.hidden.value = true;
//					console.log('file_write_success');
//				}
//			})
//		});
//	 };
	 
//	 passwordForm._children[2].onclicked_ok = function(e) {
//		 console.log('password clicked_ok');
//		 passwordForm.sendRequest();
//	 }
	 
	 return passwordForm;
 }
 
 
 
 
 
 module.exports = extendsPasswordForm;