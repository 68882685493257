"use strict";
/**
 * @command menuCommands
 */

var CoreTypes = require('src/core/CoreTypes');


var menuCommands = function(appComponent, multiSourceProvider, loadDefModalBoxComponent, passwordModalBoxComponent, confirmModalBoxComponent, isolatedComponentDefSocketListener, prefix) {
	console.log(appComponent._children[0]._children[0].view);
	
	// Menu Option : "Load a definition"
	var loadDefCommand = new CoreTypes.Command(
		function() {
			multiSourceProvider.acquireAsync();
//			modalBoxComponent.view.styleHook.s.updateRule({visibility : 'visible'}, ':host');
			loadDefModalBoxComponent.streams.hidden.value = false;
		},
		null,
		null
	);
	appComponent._children[0]._children[2]._children[1].onclicked_ok = loadDefCommand.act.bind(loadDefCommand);
	
	// Menu Option : "Save a Definition"
	var saveDefCommand = new CoreTypes.Command(
		function() {
//			var editorContent = codeEditorIFrame._innerWindow.document.body.children[0]._component._children[0]._children[0].getEditorContent();
			var editorContent = isolatedComponentDefSocketListener.callRemoteProcedure('getEditorContent');
			var body = new FormData();
			body.set('definition_code', editorContent);
			
			var options = {
				method : 'POST',
				body : body
			}
			var url = prefix + 'save_definition/';
			fetch(url, options).then(function(response) {
				response.text().then(function(responseObj) {
					responseObj = JSON.parse(responseObj);
					
					if (responseObj.password_checking === true) {
						passwordModalBoxComponent.streams.hidden.value = false;
						console.log('password request received');
					}
				})
			});
		},
		null,
		null
	);
	appComponent._children[0]._children[2]._children[2].onclicked_ok = saveDefCommand.act.bind(saveDefCommand);
	
	
	// Response handler for : "Overwrite file ?" confirmation box
	passwordModalBoxComponent._children[0].addEventListener('acknowledged', function(e) {
		if (typeof JSON.parse(e.data).overwrite_confirm !== 'undefined') {
			confirmModalBoxComponent.streams.hidden.value = false;
		}
	});


	// Menu Option : "Working Layout"
	var workingLayoutCommand = new CoreTypes.Command(
		function() {
			
			// https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/hidden
			// For instance, elements styled display: block will be displayed despite the hidden attribute's presence.
//			appComponent._children[2].streams.hidden.value = true;
//			appComponent._children[3].streams.hidden.value = true;
			
			appComponent._children[1].view.styleHook.s.updateRule({display : 'none'}, '#sampleCodeRenderedProof');
			appComponent._children[2].view.styleHook.s.updateRule({display : 'none'}, '#sampleCodeRenderedProof');
			
			// FIXME : For now, we need the "computedStyle" view to be visible in order to pass the correct
			//	dimensions to the "rasterizedRendering" view (cf. StylingSolverDebugViewRouter.js - line 147) 
			
//			appComponent._children[3].view.styleHook.s.updateRule({display : 'none'}, '#computedDataStructures');
//			appComponent._children[4].view.styleHook.s.updateRule({display : 'none'}, '#computedDataStructures');

			appComponent._children[3].view.styleHook.s.updateRule({gridRow : '1'}, '#rasterizedRendering');
			appComponent._children[4].view.styleHook.s.updateRule({gridRow : '1'}, '#rasterizedRendering');
		},
		null,
		null
	);
	appComponent._children[0]._children[3]._children[1].onclicked_ok = workingLayoutCommand.act.bind(workingLayoutCommand);
	
	
	// Menu Option : "Debug Layout"
	var debugLayoutCommand = new CoreTypes.Command(
		function() {
			
			// https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/hidden
			// For instance, elements styled display: block will be displayed despite the hidden attribute's presence.
//			appComponent._children[2].streams.hidden.value = false;
//			appComponent._children[3].streams.hidden.value = false;
			
			appComponent._children[1].view.styleHook.s.updateRule({display : 'grid'}, '#sampleCodeRenderedProof');
			appComponent._children[2].view.styleHook.s.updateRule({display : 'grid'}, '#sampleCodeRenderedProof');
			
			// FIXME : For now, we need the "computedStyle" view to be visible in order to pass the correct
			//	dimensions to the "rasterizedRendering" view (cf. StylingSolverDebugViewRouter.js - line 147) 
			
//			appComponent._children[3].view.styleHook.s.updateRule({display : 'grid'}, '#computedDataStructures');
//			appComponent._children[4].view.styleHook.s.updateRule({display : 'grid'}, '#computedDataStructures');
			
			appComponent._children[3].view.styleHook.s.updateRule({gridRow : '2'}, '#rasterizedRendering');
			appComponent._children[4].view.styleHook.s.updateRule({gridRow : '2'}, '#rasterizedRendering');
		},
		null,
		null
	);
	appComponent._children[0]._children[3]._children[2].onclicked_ok = debugLayoutCommand.act.bind(debugLayoutCommand);
	
	
	return {
		loadDefCommand : loadDefCommand,
		saveDefCommand : saveDefCommand,
		workingLayoutCommand : workingLayoutCommand,
		debugLayoutCommand : debugLayoutCommand
	};
}









 module.exports = menuCommands;