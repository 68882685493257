"use strict";
/**
 * @def FormComponentOverride
 * @isGroup true
 * 
 * @CSSify styleName : FormComponentOverrideHost
 * @CSSify styleName : FormComponentOverrideButton
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var FormComponentOverrideDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	

	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent', 				// this is not implicit if we want to use inference when building the def through the factor
			states : [
				{action : options.prefix + 'verify_password/'}
			],
			sOverride : [
				{
					selector : ':host',
					flexDirection : 'row'
				}
			]/**@CSSify Style componentStyle : FormComponentOverrideHost */
		}),
//		subSections : [
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			}),
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			})
//		],
		members : [
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'SimpleText',
					nodeName : 'h3',
					section : 0,
					props : [
						{text : 'Upload a new definition : password required'}
					]
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					host : TypeManager.createDef({
						type : 'Fieldset',
						section : 0,
						props : [
							{slotsTextContent : ['Choose a filename and enter the API password']}
						]
					})
				}),
				members : [
					TypeManager.createDef({
						host : TypeManager.createDef({
							type : 'TextInput',
//							section : 0,
							attributes : [
								{title : '-Filename'},
		//						{placeholder : ''}
							]
						})
					}),
					TypeManager.createDef({
						host : TypeManager.createDef({
							type : 'PasswordInput',
//							section : 0,
							attributes : [
								{title : '-API-Password'},
		//						{placeholder : ''}
							]
						})
					})
				]
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'SubmitButton',
					nodeName : 'button',
					section : 1,
					props : [
						{text : 'Send'}
					]
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'CancelButton',
					nodeName : 'button',
					section : 1,
					props : [
						{text : 'Cancel'}
					]
				})
			})
		]
	});
	console.log(moduleDef);
	return moduleDef;
}

module.exports = FormComponentOverrideDef;