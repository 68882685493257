"use strict";
/**
 * @def ConfirmFormComponentOverride
 * @isGroup true
 * 
 * @CSSify styleName : ConfirmFormComponentOverrideHost
 * @CSSify styleName : ConfirmFormComponentOverrideButton
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');


var ConfirmFormComponentOverrideDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	

	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent', 				// this is not implicit if we want to use inference when building the def through the factor
			states : [
				{action : options.prefix + 'overwrite_confirm/'}
			],
			sOverride : [
				{
					selector : ':host',
					flexDirection : 'row'
				}
			]/**@CSSify Style componentStyle : ConfirmFormComponentOverrideHost */
		}),
//		subSections : [
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			}),
//			TypeManager.createDef({
//				host : TypeManager.createDef({
//					type : 'ComponentWithView',
//					nodeName : 'section'
//				})
//			})
//		],
		members : [
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'SimpleText',
					nodeName : 'p',
					section : 0,
					props : [
						{text : 'The file already exists : overwrite ?'}
					],
					reactOnSelf : [
						{from : 'text', to : 'content'}
					]
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'TextInput',
					section : 0,
					attributes : [
						{id : 'overwrite'},
//						{value : "true"},
						{hidden : 'hidden'}
					]
//					,
//					state : [
//						{valid : 'true'}		// not working (why ?)
//					]
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'SubmitButton',
					nodeName : 'button',
					section : 1,
					props : [
						{text : 'Overwrite'}
					]
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					type : 'CancelButton',
					nodeName : 'button',
					section : 1,
					props : [
						{text : 'Cancel'}
					]
				})
			})
		]
	});
	console.log(moduleDef);
	return moduleDef;
}

module.exports = ConfirmFormComponentOverrideDef;