"use strict";
/**
 * @router DevToolsStructRouter 
 */

var App = require('src/core/AppIgnition');
var TypeManager = require('src/core/TypeManager');
var APIendpointsManager = require('src/core/APIendpointsManager');
//var rDataset = require('src/core/ReactiveDataset');

// Sample code to include a definition file
//var createDevToolsStructDef = require('src/UI/defs/DevToolsStructDef');
var createIFrameStructDef = require('src/clientStructures/DebugAppUIStructDef');
//var createDevToolsMenuDef = require('src/clientStructures/menuDef');
var createFileSelectorFormComponentOverrideDef = require('src/clientStructures/fileSelectorFormComponentOverrideDef');
var createPasswordBoxFormComponentOverrideDef = require('src/clientStructures/passwordBoxFormComponentOverrideDef');
var createConfirmBoxFormComponentOverrideDef = require('src/clientStructures/confirmBoxFormOverrideDef');

var decorateTypes = require('src/clientStructures/decoratedComponents');
var instanciateMenuCommands = require('src/commands/menuCommands');
var extendPasswordForm = require('src/commands/passwordFormSubClass');

// App Components
var ISocketListener = require('src/coreComponents/ISocketComponent/ISocketComponent');

var classConstructor = function() {	

	function init(DomSelector) {
		// Hack in case we're requesting the "local" server : find a potential path-prefix in the URL
		var locationTest, prefix = '';
		if ((locationTest = window.location.href.match(/localhost(\/.*?\/)/))) {
			prefix = locationTest[1];
		}
		
		// App-level providers (they consume the server-side API)
		
		var dataPresenterFunc = function(response) {
			var peers = [], key = '';
			response.payload.forEach(function(doc) {
				key = Object.keys(doc)[0];
				peers.push(doc[key]);
			}, this);
			
			return {
				endPointName : response.endPointName,
				payload : peers
			};
		};
		var multiSourceProvider = new APIendpointsManager(
			'definitons_endpoint',	// APIEndpointManager's name
			prefix,			// API path
			'',						// "GET" parameters
			dataPresenterFunc		// dataPresenterFunction
		);
		
		// Instanciate components
		
		decorateTypes();
		var DevToolsStructInstance = new App.coreComponents.CompoundComponent(createIFrameStructDef());
		
		var defLoaderBoxOverrideDef = TypeManager.mockGroupDef();
		defLoaderBoxOverrideDef.getGroupHostDef().section = 0;
		var defLoaderBox = new App.componentTypes.BigModalBox(
			defLoaderBoxOverrideDef,
			DevToolsStructInstance.view,
			DevToolsStructInstance
		);
		var passwordBox = new App.componentTypes.BigModalBox(
			defLoaderBoxOverrideDef,
			DevToolsStructInstance.view,
			DevToolsStructInstance
		);
		var confirmBox = new App.componentTypes.BigModalBox(
			defLoaderBoxOverrideDef,
			DevToolsStructInstance.view,
			DevToolsStructInstance
		);
		
		
		
		
		var fileSelectorForm = new App.componentTypes.FormComponent(
			createFileSelectorFormComponentOverrideDef('', {prefix : prefix}),
			defLoaderBox.view,
			defLoaderBox
		);
//		var passwordBoxFormFieldsetOverrideDef = TypeManager.createDef({
//			host : TypeManager.createDef({
//				props : [
//					{slotsTextContent : ['To upload a new definition, please type the password: ']}
//				]
//			})
//		});
		/*
		 * REMINDER: props and states can't be overridden AFTER the instanciation of the def,
		 * as the def factory is responsible for reflecting states & props on the streams property
		*/ 
//		passwordBoxFormFieldsetOverrideDef.getHostDef().props.push(
//			new TypeManager.propsModel(
//				{slotsTextContent : ['A password is needed to upload a new definition...']}
//			)
//		);
//		var passwordBoxFormFieldset = new App.componentTypes.Fieldset(
//			passwordBoxFormFieldsetOverrideDef,
//			passwordBox.view,
//			passwordBox
//		);
		var passwordBoxForm = new App.componentTypes.FormComponent(
			createPasswordBoxFormComponentOverrideDef('', {prefix : prefix}),
			passwordBox.view,
			passwordBox
		);
		var confirmBoxForm = new App.componentTypes.FormComponent(
			createConfirmBoxFormComponentOverrideDef('', {prefix : prefix}),
			confirmBox.view,
			confirmBox
		);
		
		// the sending of the request for this form is handled abstractively in the FormComponent
		// (via "action" stream in "PasswordBoxFormComponentOverrideDef")
//		passwordBoxForm = extendPasswordForm(passwordBoxForm, prefix);
		
//		console.log(passwordBoxForm);
		
		var slidingFileSelectorOverrideDef = TypeManager.mockDef();
//		slidingFileSelectorOverrideDef.getHostDef().sOverride = [{
//			selector : ':host',
//			width : '360px'	
//		}];
		slidingFileSelectorOverrideDef.getHostDef().section = 0;
		var slidingFileSelector = new App.decoratedTypes.FileSelectorWithDataProvider(
			slidingFileSelectorOverrideDef,
			fileSelectorForm.view,
			fileSelectorForm
		); 
		
		
		
		
		
		// RPC on the code Editor IFrame
		fileSelectorForm.addEventListener('acknowledged', function(e) {
//			console.log('acknowledged');
			isolatedComponentDefSocketListener.callRemoteProcedure('replaceEditorContent', e.data);
		});
		
		
		
		
		
		// The fileSelector shall obtain a list of files
		// via subscribing to the serviceCahnnel stream on the slidingFileSelector
		slidingFileSelector.subscribeToProvider(multiSourceProvider, 'list_definitions/');
		
		
		
		
		// Render the DOM
		new App.DelayedDecoration();
		
		// DEBUG
		console.log(DevToolsStructInstance);
		
		// Access to streams must be done after the rendering
		defLoaderBox.streams.hidden.value = true;
		passwordBox.streams.hidden.value = true;
		confirmBox.streams.hidden.value = true;
		
		// FIXME: why isn't that defined in the def ?
		// (The SlidingPanelComponent expects an Array to handle cases
		// where the title would be multiline)
		slidingFileSelector.streams.headerTitle.value = ['Select a definition file :'];

		
		// Bind the IFrames for RPC
		// => we must bind all the IFrames, as the IFrameComponent shall instanciate
		// 	 a _RPCStackComponent with the status set to 'isInnerStack'.
		//	 So this component shall start an interval loop that will never end
		//	 unless the ISocketListener passes it a reference to its internal RPCStackComponent
		// (For now, the main phase of the update of the dev tools is made of
		// IFrameComponents listening to an update event on the -sibbling- IFrameComponent
		// => this main phase is NOT handled in RPC, but we use the RPC mechanism
		// in the remote scope to get a ref on the -wrapping- IFrameComponent)
		var isolatedComponentDefSocketListener = new ISocketListener(DevToolsStructInstance._children[1]);
		isolatedComponentDefSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', isolatedComponentDefSocketListener._remoteLocation);});
		var isolatedComponentProofSocketListener = new ISocketListener(DevToolsStructInstance._children[2]);
		isolatedComponentProofSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', isolatedComponentProofSocketListener._remoteLocation);});
		var stylingSolverSocketListener = new ISocketListener(DevToolsStructInstance._children[3]);
		stylingSolverSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', stylingSolverSocketListener._remoteLocation);});
		var isolatedComponentRenderingSocketListener = new ISocketListener(DevToolsStructInstance._children[4]);
		isolatedComponentRenderingSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', isolatedComponentRenderingSocketListener._remoteLocation);});
		
		
		// Define a command for each menu-option and bind it to the corresponding menu-option
		instanciateMenuCommands(
			DevToolsStructInstance,
			multiSourceProvider,
			defLoaderBox,
			passwordBox,
			confirmBox,
			isolatedComponentDefSocketListener,
			prefix
		);
		
		// DEBUG
//		isolatedComponentDefSocketListener.registerProcedure('log', function(text) {
//			console.log(text);
//		})
		
		attachToBody(DevToolsStructInstance, DomSelector);
	}
	
	// We explicitly attach the root component to the DOM, as the app root component seems to fail
	// FIXME: see why...
	function attachToBody(rootComponent, DomSelector) {
		var connect = setInterval(function() {
			if (!rootComponent.DomLoaded) {
				if (rootComponent.view.getMasterNode()) {
					console.log('waiting for DOM');
					rootComponent.DomLoaded = true;
					document.querySelector(DomSelector).appendChild(rootComponent.view.getMasterNode());
					
					// Hack the second section not being shadowDOM-ed
					rootComponent.view.getMasterNode().style.gridTemplateRows = '44px';
					rootComponent.view.getWrappingNode().children[5].style.display = 'grid';
					
					// Hack visibility of "display: block" smart-input  ("hidden" attribute won't work in that case)
					rootComponent._children[7]._children[0]._children[1].view.getMasterNode().style.visibility = 'hidden';
					// Hack "valid" state of the hidden input
					rootComponent._children[7]._children[0]._children[1].streams.valid.value = true;
					
					// Refresh Sliding Panel size after DOM (& by the way streams) rendering
//					rootComponent._children[5]._children[0]._children[2].streams.updateTrigger.value = 'updated after DOM rendering';
				}
			}
			else {
				clearInterval(connect);
			}
		}, 512);
	}
	
	return {
		init : init
	}
}

classConstructor.__factory_name = 'DevToolsStructRouter';
module.exports = classConstructor;
