"use strict";
/**
 * @def DebugAppUIStruct
 * @isGroup true
 * 
 */

var TypeManager = require('src/core/TypeManager');
//var CreateStyle = require('src/UI/generics/GenericStyleConstructor');
//var OverrideStyle = require('src/UI/generics/GenericStyleOverride');

var createDevToolsMenuDef = require('src/clientStructures/menuDef');
var createIFrameComponentHostDef_Grid_1_1 = require('src/coreComponents/IFrameComponent/coreComponentDefs/IFrameComponentHostDef_Grid-1-1');
//var createIFrameComponentHostDef_Grid_1_2 = require('src/coreComponents/IFrameComponent/coreComponentDefs/IFrameComponentHostDef_Grid-1-2');
var createIFrameComponentHostDef_Grid_2_2 = require('src/coreComponents/IFrameComponent/coreComponentDefs/IFrameComponentHostDef_Grid-2-2');


var DebugAppUIStructDef = function(uniqueID, options) {
	
	
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'RootViewComponent',
			attributes : [
				{id : 'devtools_struct'}
			]
		}),
		subSections : [
			TypeManager.createDef({
				host : TypeManager.createDef({
					nodeName : 'section'
				})
			}),
			TypeManager.createDef({
				host : TypeManager.createDef({
					nodeName : 'section'
				})
			})
		],
		members : [
			createDevToolsMenuDef(),
			
			TypeManager.createComponentDef({
				type : 'IFrameComponent',
				section : 1,
				attributes : [
					{title : 'Sample code that shall be rendered as DOM elements'},
					{id : 'sampleCodeToBootstrapViews'},
					{src : '?page=IsolatedComponentDef'}	// &var_mode=recalcul
				],
				states : [
					
				],
				// This should be managed through annotations: 
				// That's just the use case we planned...
				// 	=> any file ending with ...Def.js is appropriate
				// to be a target for style-injection via annotations
				sWrapper : createIFrameComponentHostDef_Grid_1_1().getHostDef().sWrapper
			}, null),
			TypeManager.createComponentDef({
				type : 'IFrameComponent',
				section : 1,
				attributes : [
					{title : 'DOM elements rendered based on the sample code'},
					{id : 'sampleCodeRenderedProof'},
					{src : '?page=IsolatedComponentProof'}
				],
				states : [
					{hidden : true}
				],
				sWrapper : createIFrameComponentHostDef_Grid_1_1().getHostDef().sWrapper
			}, null),
			TypeManager.createComponentDef({
				type : 'IFrameComponent',
				section : 1,
				attributes : [
					{title : 'Computed style of DOM elements'},
					{id : 'computedDataStructures'},
					{src : '?page=StylingSolverDebugView'}
				],
				states : [
					{hidden : true}
				],
				sWrapper : createIFrameComponentHostDef_Grid_2_2().getHostDef().sWrapper
			}, null),
			TypeManager.createComponentDef({
				type : 'IFrameComponent',
				section : 1,
				attributes : [
					{title : 'DOM elements rendered as raster objects'},
					{id : 'rasterizedRendering'},
					{src : '?page=IsolatedComponentRenderingView'}
				],
				states : [
					
				],
				sWrapper : createIFrameComponentHostDef_Grid_2_2().getHostDef().sWrapper
			}, null)
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

module.exports = DebugAppUIStructDef;