"use strict";
/**
 * @def JScontentFromTextarea
 * @isGroup true
 * 
 * string (old) #7099E0
 * @CSSify styleName : JScontentFromTextareaHost
 * @CSSify styleName : JScontentFromTextareaTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var JScontentFromTextareaDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "block",
		"selector": ":host",
		"boxSizing": "border-box",
		"fontFamily": "Consolas, 'Courier New'",
		"MozTabSize": "4",
		"height": "100%",
		"width": "100%",
		"overflow": "hidden"
	},
	{
		"display": "flex",
		"flex": "1 1 0",
		"selector": "-bypassed",
		"boxSizing": "border-box"
	},
	{
		"selector": ".CodeMirror-lines",
		"padding": "4px 0"
	},
	{
		"selector": ".CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like",
		"padding": "0 4px"
	},
	{
		"selector": ".CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler",
		"backgroundColor": "white"
	},
	{
		"selector": ".CodeMirror-gutters",
		"borderRight": "1px solid #ddd",
		"backgroundColor": "#f7f7f7",
		"whiteSpace": "nowrap"
	},
	{
		"selector": ".CodeMirror-linenumber",
		"padding": "0 3px 0 5px",
		"minWidth": "20px",
		"borderRight": "1px solid #383838",
		"textAlign": "right",
		"color": "#999",
		"whiteSpace": "nowrap"
	},
	{
		"selector": ".CodeMirror-guttermarker",
		"color": "black"
	},
	{
		"selector": ".CodeMirror-guttermarker-subtle",
		"color": "#999"
	},
	{
		"selector": ".CodeMirror-cursor",
		"borderLeft": "1px solid white",
		"borderRight": "none",
		"width": "0"
	},
	{
		"selector": ".CodeMirror div.CodeMirror-secondarycursor",
		"borderLeft": "1px solid silver"
	},
	{
		"selector": ".cm-fat-cursor .CodeMirror-cursor",
		"width": "auto",
		"border": "0 !important",
		"background": "#7e7"
	},
	{
		"selector": ".cm-fat-cursor div.CodeMirror-cursors",
		"zIndex": "1"
	},
	{
		"selector": ".cm-fat-cursor-mark",
		"backgroundColor": "rgba(20, 255, 20, 0.5)"
	},
	{
		"selector": ".cm-animate-fat-cursor",
		"width": "auto",
		"border": "0",
		"backgroundColor": "#7e7"
	},
	{
		"selector": ".cm-tab",
		"display": "inline-block",
		"textDecoration": "inherit"
	},
	{
		"selector": ".CodeMirror-rulers",
		"position": "absolute",
		"left": "0",
		"right": "0",
		"top": "-50px",
		"bottom": "0",
		"overflow": "hidden"
	},
	{
		"selector": ".CodeMirror-ruler",
		"borderLeft": "1px solid #ccc",
		"top": "0",
		"bottom": "0",
		"position": "absolute"
	},
	{
		"color": "#AABBCC",
		"selector": ".cm-type"
	},
	{
		"selector": ".cm-s-default .cm-header",
		"color": "blue"
	},
	{
		"selector": ".cm-s-default .cm-quote",
		"color": "#090"
	},
	{
		"selector": ".cm-negative",
		"color": "#d44"
	},
	{
		"selector": ".cm-positive",
		"color": "#292"
	},
	{
		"selector": ".cm-header, .cm-strong",
		"fontWeight": "bold"
	},
	{
		"selector": ".cm-em",
		"fontStyle": "italic"
	},
	{
		"selector": ".cm-link",
		"textDecoration": "underline",
		"color": "#CC7832"
	},
	{
		"selector": ".cm-strikethrough",
		"textDecoration": "line-through"
	},
	{
		"selector": ".cm-s-default .cm-keyword",
		"color": "#CC7832"
	},
	{
		"selector": ".cm-s-default .cm-atom",
		"color": "#CC7832"
	},
	{
		"selector": ".cm-s-default .cm-number",
		"color": "#6897BB"
	},
	{
		"selector": ".cm-s-default .cm-def",
		"color": "#A9B7C6"
	},
	{
		"selector": ".cm-s-default .cm-variable-2",
		"color": "#A9B7C6 /*A9B7C6*/"
	},
	{
		"selector": ".cm-variable",
		"color": "#DDD"
	},
	{
		"selector": ".cm-s-default .cm-variable-3, .cm-s-default .cm-type",
		"color": "#9876AA"
	},
	{
		"selector": ".cm-s-default .cm-comment",
		"color": "#515151"
	},
	{
		"selector": ".cm-s-default .cm-string",
		"color": "#35bedb"
	},
	{
		"selector": ".cm-s-default .cm-string-2",
		"color": "#6A8759"
	},
	{
		"selector": ".cm-s-default .cm-meta",
		"color": "#BBB529"
	},
	{
		"selector": ".cm-s-default .cm-qualifier",
		"color": "#6A8759"
	},
	{
		"selector": ".cm-s-default .cm-builtin",
		"color": "#FF9E59"
	},
	{
		"selector": ".cm-property",
		"color": "#FFC66D"
	},
	{
		"selector": ".cm-s-default .cm-bracket",
		"color": "#A9B7C6"
	},
	{
		"selector": ".cm-s-default .cm-tag",
		"color": "#629755"
	},
	{
		"selector": ".cm-s-default .cm-attribute",
		"color": "#6897bb"
	},
	{
		"selector": ".cm-s-default .cm-hr",
		"color": "#999"
	},
	{
		"selector": ".cm-s-default .cm-link",
		"color": "#CC7832"
	},
	{
		"selector": ".cm-s-default .cm-error",
		"color": "#BC3F3C"
	},
	{
		"selector": ".cm-invalidchar",
		"color": "#f00"
	},
	{
		"selector": ".CodeMirror-composing",
		"borderBottom": "2px solid"
	},
	{
		"selector": "div.CodeMirror span.CodeMirror-matchingbracket",
		"color": "#0b0"
	},
	{
		"selector": "div.CodeMirror span.CodeMirror-nonmatchingbracket",
		"color": "#a22"
	},
	{
		"selector": ".CodeMirror-matchingtag",
		"background": "rgba(255, 150, 0, .3)"
	},
	{
		"selector": ".CodeMirror-activeline-background",
		"background": "#e8f2ff"
	},
	{
		"selector": ".CodeMirror",
		"position": "relative",
		"overflow": "hidden",
		"color": "#EFEFEF",
		"height": "100%",
		"width": "100%"
	},
	{
		"selector": ".CodeMirror-scroll",
		"overflow": "scroll !important",
		"marginBottom": "-50px",
		"marginRight": "-50px",
		"paddingBottom": "50px",
		"height": "100%",
		"outline": "none",
		"position": "relative"
	},
	{
		"selector": ".CodeMirror-sizer",
		"position": "relative",
		"borderRight": "50px solid transparent"
	},
	{
		"selector": ".CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler",
		"position": "absolute",
		"zIndex": "6",
		"display": "none",
		"outline": "none"
	},
	{
		"selector": ".CodeMirror-vscrollbar",
		"right": "0",
		"top": "0",
		"overflowX": "hidden",
		"overflowY": "scroll"
	},
	{
		"selector": ".CodeMirror-hscrollbar",
		"bottom": "0",
		"left": "0",
		"overflowY": "hidden",
		"overflowX": "scroll"
	},
	{
		"selector": ".CodeMirror-scrollbar-filler",
		"right": "0",
		"bottom": "0"
	},
	{
		"selector": ".CodeMirror-gutter-filler",
		"left": "0",
		"bottom": "0"
	},
	{
		"selector": ".CodeMirror-gutters",
		"position": "absolute",
		"left": "0",
		"top": "0",
		"minHeight": "100%",
		"zIndex": "3"
	},
	{
		"selector": ".CodeMirror-gutter",
		"whiteSpace": "normal",
		"height": "100%",
		"display": "inline-block",
		"verticalAlign": "top",
		"marginBottom": "-50px"
	},
	{
		"selector": ".CodeMirror-gutter-wrapper",
		"position": "absolute",
		"zIndex": "4",
		"background": "none !important",
		"border": "none !important"
	},
	{
		"selector": ".CodeMirror-gutter-background",
		"position": "absolute",
		"top": "0",
		"bottom": "0",
		"zIndex": "4"
	},
	{
		"selector": ".CodeMirror-gutter-elt",
		"background": "#212A2F",
		"position": "absolute",
		"cursor": "default",
		"zIndex": "4"
	},
	{
		"selector": ".CodeMirror-gutter-wrapper::selection",
		"backgroundColor": "transparent"
	},
	{
		"selector": ".CodeMirror-gutter-wrapper::-moz-selection",
		"backgroundColor": "transparent"
	},
	{
		"selector": ".CodeMirror-lines",
		"cursor": "text",
		"minHeight": "1px"
	},
	{
		"selector": ".CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like",
		"MozBorderRadius": "0",
		"WebkitBorderRadius": "0",
		"borderRadius": "0",
		"borderWidth": "0",
		"background": "transparent",
		"fontFamily": "inherit",
		"fontSize": "inherit",
		"margin": "0",
		"whiteSpace": "pre",
		"wordWrap": "normal",
		"lineHeight": "inherit",
		"color": "inherit",
		"zIndex": "2",
		"position": "relative",
		"overflow": "visible",
		"WebkitTapHighlightColor": "transparent",
		"WebkitFontVariantLigatures": "contextual",
		"fontVariantLigatures": "contextual",
		"paddingLeft": "2px"
	},
	{
		"selector": ".CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like",
		"wordWrap": "break-word",
		"whiteSpace": "pre-wrap",
		"wordBreak": "normal"
	},
	{
		"selector": ".CodeMirror-linebackground",
		"position": "absolute",
		"left": "0",
		"right": "0",
		"top": "0",
		"bottom": "0",
		"zIndex": "0"
	},
	{
		"selector": ".CodeMirror-linewidget",
		"position": "relative",
		"zIndex": "2",
		"padding": "0.1px"
	},
	{
		"selector": ".CodeMirror-rtl pre",
		"direction": "rtl"
	},
	{
		"selector": ".CodeMirror-code",
		"outline": "none"
	},
	{
		"selector": ".CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber",
		"MozBoxSizing": "content-box",
		"boxSizing": "content-box"
	},
	{
		"selector": ".CodeMirror-measure",
		"position": "absolute",
		"width": "100%",
		"height": "0",
		"overflow": "hidden",
		"visibility": "hidden"
	},
	{
		"selector": ".CodeMirror-cursor",
		"position": "absolute",
		"borderLeft": "1px solid #FFF",
		"pointerEvents": "none"
	},
	{
		"selector": ".CodeMirror-measure pre",
		"position": "static"
	},
	{
		"selector": "div.CodeMirror-cursors",
		"visibility": "hidden",
		"position": "relative",
		"zIndex": "3"
	},
	{
		"selector": "div.CodeMirror-dragcursors",
		"visibility": "visible"
	},
	{
		"selector": " .CodeMirror-focused div.CodeMirror-cursors",
		"visibility": "visible"
	},
	{
		"selector": ".CodeMirror-selected",
		"background": "#3A3A3A"
	},
	{
		"selector": ".CodeMirror-focused .CodeMirror-selected",
		"background": "#444"
	},
	{
		"selector": ".CodeMirror-crosshair",
		"cursor": "crosshair"
	},
	{
		"selector": ".CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span:: selection",
		"background": "#3A3A3A"
	},
	{
		"selector": ".CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span:: -moz-selection",
		"background": "#3A3A3A"
	},
	{
		"selector": ".cm-searchin g ",
		"backgroundColor": "rgba(255, 255, 0, .4)"
	},
	{
		"selector": ".cm-force-border",
		"paddingRight": ".1px"
	},
	{
		"selector": ".cm-tab-wrap-hack:after",
		"content": ""
	},
	{
		"selector": "span.CodeMirror-selectedtext",
		"background": "none"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'JScontentFromTextareaHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
			nodeName : 'augmented-textarea',		// textarea 
			attributes : [
				{id : 'JScontentFromTextarea'},
				{spellcheck : null}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}, null);
	
	return moduleDef;
}

module.exports = JScontentFromTextareaDef;