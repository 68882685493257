"use strict";
/**
 * @def DevToolsMenu
 * @isGroup true
 * 
 * @CSSify styleName : MenuBarHost
 * @CSSify styleName : MenuBarTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var DF = require('src/core/TypeManager');
var CreateStyle = require('src/UI/generics/GenericStyleConstructor');




var DevToolsMenu = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	
	var moduleDef = DF.createDef({
		host : DF.createDef({
			type : 'MenuBar',
			isCompound : true,
			section : 0
		}),
		members : [
			DF.createDef({
				host : DF.createDef({
					type : 'ComponentWithView',
					nodeName : 'app-title',
					sWrapper : CreateStyle([
						{
							selector : ':host',
							backgroundImage : 'url("plugins/DevToolsStruct/ressources/Formant_Logo_64_V4_w_text.png")',
							backgroundRepeat : 'no-repeat',
							backgroundPosition : 'center left',
							backgroundSize : 'contain',
							display : 'inline-block',
							width : '143px',
							height : '44px',
//							color : '#CCCCCC',							// 2da4be
//							fontFamily : 'NotoSerif-Italic, NotoSerif-CondensedBoldItalic, AbrilFatface-Regular',
//							fontSize : '34px',
//							fontStyle : 'italic',
							marginLeft : '12px',
//							padding : '6px',
//							paddingLeft : '32px',
//							paddingRight : '12px'
						}
//						,
//						{
//							selector : ':host span',
//							display : "inline-block"	
//						},
//						{
//							selector : ':host span::first-letter',
//							fontFamily : 'NotoSerif-BlackItalic, NotoSerif-CondensedBoldItalic, AbrilFatface-Regular',
//							color : '#818181',							// 2da4be
//							fontSize : '34px'
//						}
					])
				})
			}),
			DF.createDef({
				host : DF.createDef({
					type : 'SimpleText',
					nodeName : 'app-title',
					props : [
						{text : "Playground"}
					],
					sWrapper : CreateStyle([
						{
							selector : ':host',
							color : '#CCCCCC',							// 2da4be
//							fontFamily : 'NotoSerif-Italic, NotoSerif-CondensedBoldItalic, AbrilFatface-Regular',
							fontSize : '20px',
							paddingTop : '12px',
							paddingRight : '34px'
						},
						{
							selector : ':host span',
							display : "inline-block"	
						}
					])
				})
			}),
			DF.createDef({
				host : DF.createDef({
					type : 'Menu',
					isCompound : true
				}),
				members : [
					DF.createDef({
						host : DF.createDef({
							type : 'SimpleText',
							nodeName : 'menu-title',
							props : [
								{text : 'Definition'}
							]
						})
					}),
					DF.createDef({
						host : DF.createDef({
							type : 'MenuOption',
							props : [
								{title : 'Load Definition...'}
							]
						})
					}),
					DF.createDef({
						host : DF.createDef({
							type : 'MenuOption',
							props : [
								{title : 'Save Definition...'}
							]
						})
					})
				]
			}),
			DF.createDef({
				host : DF.createDef({
					type : 'Menu',
					isCompound : true
				}),
				members : [
					DF.createDef({
						host : DF.createDef({
							type : 'SimpleText',
							nodeName : 'menu-title',
							props : [
								{text : 'Layout'}
							]
						})
					}),
					DF.createDef({
						host : DF.createDef({
							type : 'MenuOption',
							props : [
								{title : 'Working Layout'}
							]
						})
					}),
					DF.createDef({
						host : DF.createDef({
							type : 'MenuOption',
							props : [
								{title : 'Debug Layout'}
							]
						})
					})
				]
			})
		]
	});
	
//	console.log(moduleDef);
	
	return moduleDef;
}

module.exports = DevToolsMenu;